import {createRouter,createWebHistory} from 'vue-router'
const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../components/home')
    },
	{
		path: '/term',
		name: 'term',
		component: () => import('../components/term')
    },
	{
		path: '/sparde',
		name: 'sparde',
		component: () => import('../components/sparde')
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes,
	scrollBehavior(){return {top:0}}
})

export default router
